:root[data-theme='light'] {
  color-scheme: light;
}
:root[data-theme='dark'] {
  color-scheme: dark;
}

// Remove the Edge password reveal button
// https://learn.microsoft.com/en-us/microsoft-edge/web-platform/password-reveal
::-ms-reveal {
  display: none;
}
